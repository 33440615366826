import React, { useState } from 'react';
import Navbar from '../Components/Navbar'
import img from '../Assets/hand.png'
import year from '../Assets/year.png'
import img2 from '../Assets/Group 1000001783.png'
import globe from '../Assets/globe.png'
import Footer from '../Components/Footer'
import box from '../Assets/donate.png'
import f1 from '../Assets/Background-1.png'
function Ngo() {
    const accordionData = [
        { title: "Education and training program", content: "Our Education and Training Program is designed to equip individuals with the knowledge, skills, and opportunities they need to thrive." },
        { title: "Promoting wellness and security", content: "Content for item 2" },
        { title: "Responding swiftly to disasters", content: "Content for item 3" },
        { title: "Brighter tomorrow", content: "Content for item 4" },

      ];
    
      const [openIndex, setOpenIndex] = useState(null); // State to track the open accordion index
    
      const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle the open/close state
      };
    
  return (
    <>
        <Navbar/>
        <div className='banner banner5'>
    <h2 data-aos="fade-right">NGO</h2>

    <p data-aos="fade-right">Home / NGO</p>
   
    </div>
    <div className='ngo'>
    <div className='ngo-left'>
    <p data-aos="fade-right">NGO</p>
    <h3 data-aos="fade-right">Embracing discomfort, we propel
towards meaningful transformation,
shaping a brighter future with every
courageous decision we make.</h3>

    </div>
    <div className='ngo-right'>
<img data-aos="zoom-in" src={img}/>
</div>

    </div>
    <div className='exps'>
    <div className='exps-left'>
    <img src={year}/>

</div>
 <div className='exps-right'>
 <div className='exp-text'>
 <div className='exp-text1'>
 <p>At CharityFlow, our mission is
simple to make a positive impact
in the lives of those who need it
most. Founded in 2008, our
organization has been dedicated
to Briefly describing the main
focus, e.g., 'improving education
for underprivileged children',
'providing clean water solutions',
or 'offering medical aid to crisis-
hit regions'</p>

</div>
 <div className='exp-text1'>
 <p>At CharityFlow, our mission is
simple to make a positive impact
in the lives of those who need it
most. Founded in 2008, our
organization has been dedicated
to Briefly describing the main
focus, e.g., 'improving education
for underprivileged children',
'providing clean water solutions',
or 'offering medical aid to crisis-
hit regions'</p>

</div>

 </div>
 <div className='exp-count'>
<div className='exp-count1'>
    <h4>140<span>+</span></h4>
    <p>Volunteers
joined this
month</p>
</div>
<div className='exp-count1'>
    <h4>140<span>+</span></h4>
    <p>Volunteers
joined this
month</p>
</div>
<div className='exp-count1'>
    <h4>140<span>+</span></h4>
    <p>Volunteers
joined this
month</p>
</div>
 </div>

</div>
    </div>
    <div className='donate' data-aos="zoom-in">
    <div className='donate-div'>
  <div>

  <h3>Your donation
<br/>
empowers those
<br/>

in need</h3>
<br/>
<br/>
<div className='donate-section'>
    <img src={img2}/>
    <p>More than 5 million
<br/>
people from around
<br/>
the world have joined
<br/>
and helped our
community.</p>
</div>
  </div>
<div className='donation-box-img'>
  <img src={box}/>

</div>

<button>Donate now</button>


</div>

    </div>
    <div className='mission'>
    <h2>The mission of our
    organization</h2>
    <br/>

    <h4>Explore our diverse range of programs and initiatives aimed at fostering<br/>
    positive change and making a tangible impact in communities worldwide.</h4>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
<div className='globe'>
<div className='globe-left'>
<div>







{accordionData.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(index)}
          >
            <h3>{item.title}</h3>
            <span>{openIndex === index ? '-' : '+'}</span>
          </div>
          {openIndex === index && (
            <div className="accordion-content">
              <p>{item.content}</p>
            </div>
          )}
        </div>
      ))}















</div>
</div>
<div className='globe-right'>
<img src={globe}/>
</div>
</div>



    </div>
    <div className='cause '>
    <div className='cause-left'>
<h3>How can your
support our
cause?</h3>
    

    </div>
    <div className='cause-right'>
    <div className='cause-cards'>
   <div>
   <div className='cause-card'>
<img src={f1}/>
<br/>
<br/>
<h3>Financial contributions</h3>


<p>With your support, we can fund vital
programs, expand our reach, and make
a tangible difference.</p>
</div>
   </div>
   <div>
   <div className='cause-card'>
<img src={f1}/>
<br/>
<br/>
<h3>Financial contributions</h3>


<p>With your support, we can fund vital
programs, expand our reach, and make
a tangible difference.</p>
</div>
   </div>
   <div>
   <div className='cause-card'>
<img src={f1}/>
<br/>
<br/>
<h3>Financial contributions</h3>


<p>With your support, we can fund vital
programs, expand our reach, and make
a tangible difference.</p>
</div>
   </div>
   <div>
   <div className='cause-card'>
<img src={f1}/>
<br/>
<br/>
<h3>Financial contributions</h3>


<p>With your support, we can fund vital
programs, expand our reach, and make
a tangible difference.</p>
</div>
   </div>
    </div>

    

</div>


    </div>
    <Footer/>
    </>
  )
}

export default Ngo