import React, { useEffect, useState } from 'react';
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar';
import axios from 'axios';

function Workshop() {
  const [workshops, setWorkshops] = useState([]); // State to hold the workshop data

  // Fetch workshops data on component mount
  useEffect(() => {
    const fetchWorkshops = async () => {
      try {
        const response = await axios.get('https://onexinfinity-backend.vercel.app/api/workshop');
        console.log('Workshops fetched:', response.data.data); // Debugging log for fetched data
        setWorkshops(response.data.data); // Update the state with fetched data
      } catch (error) {
        console.error('Error fetching workshops:', error);
        alert('Failed to fetch workshops. Please try again later.');
      }
    };

    fetchWorkshops();
  }, []);

  return (
    <>
      <Navbar />
      <div className='banner banner2'>
    <h2 data-aos="fade-right">Event And Workshop</h2>
  
    <p data-aos="fade-right">Home / Event And Workshop</p>
   
    </div>
    <br/>
    <br/>
    <div className='Workshop-heading'>
    <h2 data-aos="fade-right">Event <span>& Workshop</span></h2>

    </div>
      <div className='Workshop-cards'>

        {workshops.map((workshop) => (
          <div key={workshop._id} className='Workshop-card'>
            <div className='center'>
              <img src={workshop.image} alt={workshop.title} />
            </div>
            <div className='eventdate'>
            {new Date(workshop.date).toLocaleDateString(undefined, { day: '2-digit', month: 'short' })}
            </div>
            <div className='eventcity'>
            {workshop.location}
            </div>
            <div className='Workshop-text'>
              <h2>{workshop.title}</h2>
              <div style={{padding:'15px'}}>
              <h3 dangerouslySetInnerHTML={{ __html: workshop.description }}></h3>
            
            <div className='speaker'>
              <h5>~ {workshop.speaker}</h5>
              
            </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
}

export default Workshop;
