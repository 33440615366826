
import Navbar from '../Components/Navbar'
import brand from '../Assets//Vector.png'
import icon from '../Assets/fixed-width.png'
import icon2 from '../Assets/fixed-width2.png'
import icon3 from '../Assets/fixed-width3.png'
import icon4 from '../Assets/fixed-width4.png'

import social from '../Assets/social.png'
import cources from '../Assets/fixed-height121.png'
import blog from '../Assets/Rectangle 16.png'
import img from '../Assets/PARTNERS.png'
import Footer from '../Components/Footer'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import row from '../Assets/marq (1).png'
import row2 from '../Assets/marq (2).png'
import row3 from '../Assets/marq (3).png'
import row4 from '../Assets/marq (4).png'
import row5 from '../Assets/marq (5).png'


function Home() {
 
  const [blogs, setBlogs] = useState([]);
  const [courses, setCourses] = useState([]); // State to hold the list of courses
  const [modal, setModal] = useState(false); // State to control modal visibility
  const [selectedCourse, setSelectedCourse] = useState(null); // State to hold selected course details

  // Fetch courses on component mount
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get('https://onexinfinity-backend.vercel.app/api/course');
        console.log('Courses fetched:', response.data.data); // Debugging log for courses data
        setCourses(response.data.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
        alert('Failed to fetch courses. Please try again later.');
      }
    };

    fetchCourses();
  }, []);

  // Function to handle enroll button click
  const handleEnrollNow = (courseId) => {
    console.log('Enroll button clicked for course ID:', courseId); // Debugging log
    setModal(true); // Open modal
    fetchCourseDetails(courseId); // Fetch course details after opening modal
  };

  // Fetch course details based on selected course ID
  const fetchCourseDetails = async (courseId) => {
    try {
      const response = await axios.get(`https://onexinfinity-backend.vercel.app/api/course/${courseId}`);
      console.log('Course details fetched:', response.data.data); // Debugging log for course details
      setSelectedCourse(response.data.data); // Set course details
    } catch (error) {
      console.error('Error fetching course details:', error);
      alert('Failed to fetch course details. Please try again later.');
      setSelectedCourse(null); // Reset selected course on error
    }
  };


  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://onexinfinity-backend.vercel.app/api/blogs/');
        if (response.data && response.data.data) {
          setBlogs(response.data.data); // Set the fetched blogs data
        } else {
          console.error('Unexpected API response structure:', response);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);


  const [workshops, setWorkshops] = useState([]); // State to hold the workshop data

  // Fetch workshops data on component mount
  useEffect(() => {
    const fetchWorkshops = async () => {
      try {
        const response = await axios.get('https://onexinfinity-backend.vercel.app/api/workshop');
        console.log('Workshops fetched:', response.data.data); // Debugging log for fetched data
        setWorkshops(response.data.data); // Update the state with fetched data
      } catch (error) {
        console.error('Error fetching workshops:', error);
        alert('Failed to fetch workshops. Please try again later.');
      }
    };

    fetchWorkshops();
  }, []);
  return (
   <>
    <Navbar/>
    <div className='banner'>
    <h2 data-aos="fade-right">Awareness Is<br/>
    Everything</h2>
  
    <p data-aos="fade-right">Make sure all the choices you make in life come from<br/> a point of awareness and not ignorance.</p>
    <br/>

    <button data-aos="zoom-in">Get Started</button>
    </div>
    <div className='ourpartner'>
    
    <h3 data-aos="fade-right">Our <span>Partners</span></h3>
    <br/>
    <br/>

    <img src={brand}/>

    </div>
    <div className='about-divss'>

    <div className='about-div'>
   <div className='about-div-heading'>
  
    <h3 data-aos="zoom-in" >About <span>OneXinfinity</span></h3>
    <p data-aos="zoom-in">we understand the challenges and complexities that businesses face in today's dynamic marketplace. That's why we offer a comprehensive suite of consulting services.
    Founded on the principles of innovation, integrity, and excellence, we are a team of  seasoned professionals.
    Founded on the principles of innovation, integrity, and excellence, we are a team of  seasoned professionals.</p>

   </div>
   <br/>
   <br/>
   <br/>
   <br/>
   <div className='entrepreneur-div'>
    <div className='entrepreneur'>
    <img src={icon}/>
    <br/>
    <br/>
    <h3>Entrepreneur</h3>
    <p>The gradual accumulation of <br/>
information about atomic and  <br/>
small-scale behaviour...</p>

    </div>
    <div className='entrepreneur'>
    <img src={icon2}/>
    <br/>
    <br/>
    <h3>Content Creator</h3>
    <p>The gradual accumulation of <br/>
information about atomic and  <br/>
small-scale behaviour...</p>

    </div>
    <div className='entrepreneur'>
    <img src={icon3}/>
    <br/>
    <br/>
    <h3>Author</h3>
    <p>The gradual accumulation of <br/>
information about atomic and  <br/>
small-scale behaviour...</p>

    </div>
    <div className='entrepreneur'>
    <img src={icon4}/>
    <br/>
    <br/>
    <h3>Investor</h3>
    <p>The gradual accumulation of <br/>
information about atomic and  <br/>
small-scale behaviour...</p>

    </div>
   </div>

    </div>

    </div>

    <div className='Find  '>
    <div className='Find-left'>
   
    <h4 data-aos="fade-right">Find <span>Nikhil</span></h4>
    <br/>
    <br/>
    <h3 data-aos="fade-right">OneXinfinity has a strong community of 10Mn+ followers across all popular social media.</h3>

</div>
  <div className='Find-right'>
  <img data-aos="zoom-in" src={social}/>

</div>
    </div>
    <br/>
    <br/>
    <div className='COURSES'>

 <div className='COURSES-heading'>
 
 <h2 data-aos="zoom-in">Our <span>Courses</span></h2>
 </div>
 <br/>
 <br/>
 <div className="COURSES-cards">
          {courses.map((course) => (
            <div key={course._id} className="COURSES-card">
              <img src={course.image} alt={course.title} />
              <div className="COURSES-card-text">
                <p>{course.title}</p>
                <h3>{course.description}</h3>
                <h4>Duration: {course.duration} weeks</h4>
               
              </div>
              <div className='center'>
                <button className='enrollnow' onClick={() => handleEnrollNow(course._id)}>Enroll Now</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br />
      <br />
      <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          {selectedCourse ? selectedCourse.title : 'Loading...'}
        </ModalHeader>
        <ModalBody>
          {selectedCourse ? (
            <div className='modal-div'>
            <div className='modal-div-left'>
            <img
                src={selectedCourse.image}
                alt={selectedCourse.title}
                style={{ width: '100%', marginBottom: '20px' }}
              />
            </div>
            <div className='modal-div-right'>
            <h2>{selectedCourse.title}</h2>

            <h3>{selectedCourse.description}</h3>
            <p><strong>Duration:</strong> {selectedCourse.duration} Month</p>
            <h4><strong>Instructor:</strong> {selectedCourse.instructor} Month</h4>
            <br/>
            <div className='center'>
               <button className='enrollnow enrollnow2'>Enroll Now</button>
             </div>


            

</div>
             
             
            </div>
          ) : (
            <p>Loading course details...</p>
          )}
        </ModalBody>
      </Modal>
     <br/>
    <br/>
   

    <div className='blogs'>
  
    <h3 data-aos="zoom-in">Our <span>Blogs</span></h3>
    <br/>
    <br/>
    <div className="blogs-cards">
      {blogs.length > 0 ? (
        blogs.map((blog) => {
          // Formatting the published date
          const publishedDate = new Date(blog.publishedAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });

          return (
            <div key={blog._id} className="blogs-card">
              <img src={blog.image} alt={blog.title} />
            

         <div className='blogs-card-text'>
         <h3>{blog.title}</h3>
              <br />
              <div className="dates">
                <p>{publishedDate}</p>
                <Link to={`/blogdetail/${blog._id}`}>
        <h4>Read More</h4> {/* Link to detailed view */}
      </Link>
              </div>
         </div>
            </div>
          );
        })
      ) : (
        <p>Loading blogs...</p> // Simple loading state while fetching data
      )}
    </div>
    

    </div>
    <br/>
    <br/>
    
    <div className='Workshop-heading'>
    <h2 data-aos="zoom-in">Event <span>& Workshop</span></h2>

    </div>
      <div className='Workshop-cards'>

        {workshops.map((workshop) => (
          <div key={workshop._id} className='Workshop-card'>
            <div className='center'>
              <img src={workshop.image} alt={workshop.title} />
            </div>
            <div className='eventdate'>
            {new Date(workshop.date).toLocaleDateString(undefined, { day: '2-digit', month: 'short' })}
            </div>
            <div className='eventcity'>
            {workshop.location}
            </div>
            <div className='Workshop-text'>
              <h2>{workshop.title}</h2>
              <div style={{padding:'15px'}}>
              <h3 dangerouslySetInnerHTML={{ __html: workshop.description }}></h3>
            
            <div className='speaker'>
              <h5>~ {workshop.speaker}</h5>
              
            </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    <div className='PARTNERS'>
    
    <h3 data-aos="zoom-in">Connect with me for a <span>speaking engagement?</span></h3>
    {/* <div class="marquee">
  <div class="marquee-content">
    <img src={row} alt="Image 1"/>
    <img src={row} alt="Image 2"/>
    <img src={row} alt="Image 3"/>
    <img src={row} alt="Image 4"/>
  </div>
</div> */}
<br/>
<br/>
<marquee>
<div className='marquee'>

<img src={row} alt="Image 1"/>
    <img src={row2} alt="Image 2"/>
    <img src={row3} alt="Image 3"/>
    <img src={row4} alt="Image 4"/>
    <img src={row5} alt="Image 4"/>
    <img src={row} alt="Image 1"/>
    <img src={row2} alt="Image 2"/>
    <img src={row3} alt="Image 3"/>
    <img src={row4} alt="Image 4"/>
    <img src={row5} alt="Image 4"/>
    <img src={row} alt="Image 1"/>
    <img src={row2} alt="Image 2"/>
    <img src={row3} alt="Image 3"/>
    <img src={row4} alt="Image 4"/>
    <img src={row5} alt="Image 4"/>
</div>


</marquee>

    </div>
  <br/>
    <br/>
{/*       <br/>
    <br/>   
    <div className='joinus' >
    <div className='joinus-left' >
    <img src={img}/>
        
        </div>
        <div className='joinus-right'>
       

        <h2 data-aos="fade-right">JOIN <span>US</span></h2>
        
        <h3 data-aos="fade-right">Problems trying to resolve the conflict between <br/>
        the two major realms of Classical physics: Newtonian mechanics </h3>
        <br/>
  
        <div className='newsletter' data-aos="fade-right">
        <input placeholder='Your Email'/>
        <button >Subscribe</button>

        </div>
        </div>
        
    </div>

  
    <br/>
    <br/> */}

    <Footer/>
   </>
  )
}

export default Home
