import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

function OurCompanies() {
  return (
    <>
        <Navbar/>
        <div className='banner banner8'>
    <h2 data-aos="fade-right">Our Companies</h2>
   
    <p data-aos="fade-right">Home / Companies</p>
   
    </div>
    <br/>
    <br/>
    <div className='OurCompanies'>
    <div className='OurCompanies-left'>
    <h2>X One Developers</h2>
    <br/>
    <p>Read More</p>

</div>
 <div className='OurCompanies-right'>
<div className='OurCompanies-para'>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex nseq at. duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  sint occae cat cupidatat non proident,</p>

</div>
</div>
    </div>
    <div className='OurCompanies'>
    <div className='OurCompanies-left'>
    <h2>X One Developers</h2>
    <br/>
    <p>Read More</p>

</div>
 <div className='OurCompanies-right'>
<div className='OurCompanies-para'>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex nseq at. duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  sint occae cat cupidatat non proident,</p>

</div>
</div>
    </div>
    <div className='OurCompanies'>
    <div className='OurCompanies-left'>
    <h2>X One Developers</h2>
    <br/>
    <p>Read More</p>

</div>
 <div className='OurCompanies-right'>
<div className='OurCompanies-para'>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex nseq at. duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  sint occae cat cupidatat non proident,</p>

</div>
</div>
    </div>
    <div className='OurCompanies'>
    <div className='OurCompanies-left'>
    <h2>X One Developers</h2>
    <br/>
    <p>Read More</p>

</div>
 <div className='OurCompanies-right'>
<div className='OurCompanies-para'>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna aliqua. ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex nseq at. duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla  sint occae cat cupidatat non proident,</p>

</div>
</div>
    </div>
    <Footer/>
    </>
  )
}

export default OurCompanies