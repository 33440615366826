import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../Assets/infinityx logo.png';


function Navbar() {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const toggleDivRef = useRef(null);

  // Function to open the toggle menu
  const openToggleDiv = () => {
    setIsDivVisible(true);
  };

  // Function to close the toggle menu
  const closeToggleDiv = () => {
    setIsDivVisible(false);
  };

  // Function to handle click outside of the toggle div
  const handleClickOutside = (event) => {
    if (toggleDivRef.current && !toggleDivRef.current.contains(event.target)) {
      closeToggleDiv(); // Close the menu if clicked outside
    }
  };

  useEffect(() => {
    if (isDivVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDivVisible]);

  return (
    <>
      <nav>
        <h3>OneXInfinity</h3>
        <ul>
          <NavLink exact className="none" activeClassName="active" to="/">
            <li>Home</li>
          </NavLink>
          <NavLink className="none" activeClassName="active" to="/about">
            <li>About Us</li>
          </NavLink>
          <NavLink className="none" activeClassName="active" to="/blog">
            <li>Blogs</li>
          </NavLink>
          <NavLink className="none" activeClassName="active" to="/allcourses">
            <li>Courses</li>
          </NavLink>
          <NavLink className="none" activeClassName="active" to="/workshop">
            <li>Workshop</li>
          </NavLink>
          <NavLink className="none" activeClassName="active" to="/ngo">
            <li>NGO</li>
          </NavLink>
          <NavLink className="none" activeClassName="active" to="/angel">
            <li>Angel Investing</li>
          </NavLink>
        </ul>
        <NavLink to="/contact">
          <button>Contact Us</button>
        </NavLink>
        <i
          style={{ color: 'white', cursor: 'pointer' }}
          className="bi bi-list"
          onClick={isDivVisible ? closeToggleDiv : openToggleDiv} // Conditional onClick
        ></i>
      </nav>
      {isDivVisible && (
        <div className="toggleDiv-ops">
          <div className="toggleDiv" ref={toggleDivRef}>
            <ul>
              <NavLink exact className="none" activeClassName="active" to="/">
                <li>Home</li>
              </NavLink>
              <NavLink className="none" activeClassName="active" to="/about">
                <li>About Us</li>
              </NavLink>
              <NavLink className="none" activeClassName="active" to="/blog">
                <li>Blogs</li>
              </NavLink>
              <NavLink className="none" activeClassName="active" to="/allcourses">
                <li>Courses</li>
              </NavLink>
              <NavLink className="none" activeClassName="active" to="/ngo">
                <li>NGO</li>
              </NavLink>
              <NavLink className="none" activeClassName="active" to="/angel">
                <li>Angel Investing</li>
              </NavLink>
              <NavLink className="none" activeClassName="active" to="/">
                <li><img className='logo' src={logo} alt="Logo"/></li>
              </NavLink>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
