import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom'; // To capture route parameters
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img from '../Assets/Group 1000001775.png'
function BlogDetails() {
    const { id } = useParams(); // Capture the blog ID from the URL (if you set up dynamic routing)
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
  
    const [blogs, setBlogs] = useState([]);



    useEffect(() => {
        const fetchBlogs = async () => {
          try {
            const response = await axios.get('https://onexinfinity-backend.vercel.app/api/blogs/');
            if (response.data && response.data.data) {
              setBlogs(response.data.data); // Set the fetched blogs data
            } else {
              console.error('Unexpected API response structure:', response);
            }
          } catch (error) {
            console.error('Error fetching blogs:', error);
          }
        };
    
        fetchBlogs();
      }, []);
    useEffect(() => {
      const fetchBlogDetail = async () => {
        try {
          // Fetch the blog details using the given API endpoint and ID
          const response = await axios.get(`https://onexinfinity-backend.vercel.app/api/blogs/${id}`);
          if (response.data && response.data.data) {
            setBlog(response.data.data);
          } else {
            console.error('Unexpected API response structure:', response);
          }
        } catch (error) {
          console.error('Error fetching blog details:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchBlogDetail();
    }, [id]);
  
    if (loading) return <p>Loading blog details...</p>;
    if (!blog) return <p>Blog not found!</p>;

  return (
    <>

    <Navbar/>
    <div className='banner banner9'>
    <h2>Blog Detail</h2>
    <br/>
    <p>Home / Blogs / Blog Detail</p>
   
    </div>


     <div className="blog-detail">
     <div className="blog-detailright">
        <img src={blog.image} alt={blog.title} />
      </div>
      <div className="blog-detailleft">
        <h2>{blog.title}</h2>
      
        <br />
        <div dangerouslySetInnerHTML={{ __html: blog.content }} /> {/* Render HTML content safely */}
        <br />
        <h3>~ {blog.author}</h3>
      </div>
    
    </div>



    <div className='blogs'>
  
  <h3>More Blogs</h3>
  <br/>
  <br/>


  <div className="blogs-cards">
    {blogs.length > 0 ? (
      blogs.map((blog) => {
        // Formatting the published date
        const publishedDate = new Date(blog.publishedAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });

        return (
          <div key={blog._id} className="blogs-card">
            <img src={blog.image} alt={blog.title} />
            <br />
            <br />
            <h3>{blog.title}</h3>
            <br />
            <div className="dates">
              <p>{publishedDate}</p>
              <Link to={`/blogdetail/${blog._id}`}>
      <h4>Read More</h4> {/* Link to detailed view */}
    </Link>
            </div>
          </div>
        );
      })
    ) : (
      <p>Loading blogs...</p> // Simple loading state while fetching data
    )}
  </div>
  

  </div>
        <Footer/>
    </>
  )
}

export default BlogDetails