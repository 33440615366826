import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import OurCompanies from "./Pages/OurCompanies";
import AngelInvesting from "./Pages/AngelInvesting";
import Ngo from "./Pages/Ngo";
import Blog from "./Pages/Blog";
import BlogDetails from "./Pages/BlogDetails";
import Allcourses from "./Pages/Allcourses";
import React, { useEffect } from "react";
  import Aos from 'aos'
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.css'
import Workshop from "./Pages/Workshop";

function App() {
  useEffect(()=>{
    Aos.init({duration: 2000});
})
  return (
    <>
    <Router>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/ourcompanies" element={<OurCompanies/>} />
            <Route path="/angel" element={<AngelInvesting/>} />
            <Route path="/ngo" element={<Ngo/>} />
            <Route path="/allcourses" element={<Allcourses/>} />
            <Route path="/workshop" element={<Workshop/>} />


            <Route path="/blog" element={<Blog/>} />
            <Route path="/blogdetail/:id" element={<BlogDetails />} />







  
          </Routes>
        </Router>
    
    </>
  );
}

export default App;
