import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../Components/Navbar'
import icon1 from '../Assets/bxs_phone-call.png'
import icon2 from '../Assets/Vector-1.png'
import icon3 from '../Assets/place.png'
import social from '../Assets/social (1).png'
import social2 from '../Assets/social (2).png'
import social3 from '../Assets/social (3).png'
import Footer from '../Components/Footer'


function Contact() {
 // State variables for form inputs
 const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    subject: '',
    message: '',
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Post form data to the API
      const response = await axios.post('https://onexinfinity-backend.vercel.app/api/contact/create', formData);

      if (response.status === 200) {
        alert('Thank you! Your message has been submitted successfully.'); // Show alert box
        setFormData({ firstName: '', lastName: '', email: '', phoneNo: '', subject: '', message: '' }); // Reset form
      }
    } catch (error) {
      console.error('Error submitting the contact form:', error);
    }
  };
  return (
    <>
        <Navbar/>
        <div className='banner banner7'>
    <h2 data-aos="fade-right">Contact Us</h2>
  
    <p data-aos="fade-right">Home / Contact Us</p>
   
    </div>
    {/* <div className='contactus-div'>
    <div className='contactus'>
    <div className='contactus-left'>
    <h3>Contact Information</h3>
    <h4>Say something to start a live chat!</h4>
<br/>
<br/>
<br/>
<br/>
    <p>
        <img src={icon1}/>
        +1012 3456 789
    </p>
<br/>
<br/>

    <p>
        <img src={icon2}/>
        +1012 3456 789
    </p>
<br/>
<br/>

    <p>
        <img src={icon3}/>
        +1012 3456 789
    </p>
<br/>
<br/>
<br/>
<br/>
<div className='social-links'>
    <img src={social}/>
    <img src={social2}/>
    <img src={social3}/>

</div>
    

    </div>
    <div className='contactus-right'>
      <form onSubmit={handleSubmit}>
        <div className='contactips-div'>
          <div className='contactips'>
            <label>First Name</label>
            <input name='firstName' value={formData.firstName} onChange={handleChange} required />
          </div>
          <div className='contactips'>
            <label>Last Name</label>
            <input name='lastName' value={formData.lastName} onChange={handleChange} required />
          </div>
        </div>
        <br />
        <br />
        <div className='contactips-div'>
          <div className='contactips'>
            <label>Email</label>
            <input type='email' name='email' value={formData.email} onChange={handleChange} required />
          </div>
          <div className='contactips'>
            <label>Phone No</label>
            <input name='phoneNo' value={formData.phoneNo} onChange={handleChange} required />
          </div>
        </div>
        <br />
        <br />
        <div className='contactips-div'>
          <div className='contactips'>
            <label>Subject</label>
            <input name='subject' value={formData.subject} onChange={handleChange} required />
          </div>
        </div>
        <br />
        <br />
        <div className='contactips-div'>
          <div className='contactips'>
            <label>Message</label>
            <input name='message' value={formData.message} onChange={handleChange} required />
          </div>
        </div>
        <br />
        <br />
        <button type='submit'>Send Message</button>
      </form>
    </div>

    </div>
    </div> */}
    <div className='new-contact'>
    <div className='new-contact-left'>
    <h2>Contact Information</h2>
    <p>Say something to start a live chat!</p>
    <br/>
    <br/>
    <div className='new-contact-icons'>
    <div className='new-contact-icon'>

    <img src={icon1}/>
    </div>
    <p>+91-9874563210</p>
    </div>
    <br/>

    <div className='new-contact-icons'>
    <div className='new-contact-icon'>

    <img src={icon2}/>
    </div>
    <p>onexinfinity@gmail.com</p>
    </div>
    <br/>

    <div className='new-contact-icons'>
    <div className='new-contact-icon'>

    <img src={icon3}/>
    </div>
    <p>India,India</p>
    </div>
<br/>
<br/>

<div className='social-links'>
    <img src={social}/>
    <img src={social2}/>
    <img src={social3}/>

</div>
</div>
<div className='new-contact-right'>
<form className='new-contact-form' onSubmit={handleSubmit}>
<div className='contactips-div'>
          <div className='contactips'>
            <label>First Name</label>
            <input name='firstName' value={formData.firstName} onChange={handleChange} required />
          </div>
          <div className='contactips'>
            <label>Last Name</label>
            <input name='lastName' value={formData.lastName} onChange={handleChange} required />
          </div>
        </div>
        <br />
        <br />
        <div className='contactips-div'>
          <div className='contactips'>
            <label>Email</label>
            <input type='email' name='email' value={formData.email} onChange={handleChange} required />
          </div>
          <div className='contactips'>
            <label>Phone No</label>
            <input name='phoneNo' value={formData.phoneNo} onChange={handleChange} required />
          </div>
        </div>
        <br />
        <br />
        <div className='contactips-div'>
          <div className='contactips'>
            <label>Subject</label>
            <input name='subject' value={formData.subject} onChange={handleChange} required />
          </div>
        </div>
        <br />
        <br />
        <div className='contactips-div'>
          <div className='contactips'>
            <label>Message</label>
            <input name='message' value={formData.message} onChange={handleChange} required />
          </div>
        </div>
        <br />
        <br />
        <button type='submit'>Send Message</button>
        <br />
        <br />

</form>

</div>
    </div>
    <Footer/>
    </>
  )
}

export default Contact