import React from 'react'
import Navbar from '../Components/Navbar'
import cycle from '../Assets/cycle.png'
import img from '../Assets/ch.png'
import Footer from '../Components/Footer'
function AngelInvesting() {
  return (
  <>
    <Navbar/>
    <div className='banner banner6'>
    <h2 data-aos="fade-right">Angel Investing</h2>
    
    <p data-aos="fade-right">Home / Angel Investing</p>
   
    </div>
    <div className='legendary'>
    <h2 data-aos="fade-right">We help the daring build legendary companies.</h2>
    <br/>
    <br/>
    <div className='legendary-div'>
    <div className='legendary-left'>
<h3>Collaborative.Flexible. Innovative.</h3>
<p>Pioneering venture capital solutions</p>
<h4>Industry Ventures has cultivated new segments of the venture capital market for over two decades. Today, we manage over $8 billion of institutional capital and support founders, general partners, and institutions across the private tech ecosystem. Our funds invest into companies and venture capital partnerships directly and via secondary transactions.</h4>
</div>
 <div className='legendary-right'>
 <div className='legendary-cards'>
  <div className='no'>
  01

  </div>
  <div className='amounts'>
<h3>$8b+</h3>
  </div>
  <div className='leg-name'>
<p>Assets under Management</p>
  </div>
 </div>
 <br/>
 <br/>


 <div className='legendary-cards'>
  <div className='no'>
  01

  </div>
  <div className='amounts'>
<h3>$8b+</h3>
  </div>
  <div className='leg-name'>
<p>Assets under Management</p>
  </div>
 </div>
 <br/>
 <br/>


 <div className='legendary-cards'>
  <div className='no'>
  01

  </div>
  <div className='amounts'>
<h3>$8b+</h3>
  </div>
  <div className='leg-name'>
<p>Assets under Management</p>
  </div>
 </div>

</div>
    </div>

    </div>
    {/* <div className='lifecycle'>
   
    <h3>Complete Platform Across Venture  
    <span> Capital Lifecycle</span></h3>
    <h4>Industry Ventures’ complementary investment strategies allows us to be a partner to fund managers and<br/> entrepreneurs from inception to end of fund life and provides investment exposure from pre-seed to pre-IPO companies.</h4>
<br/>
<br/>
<br/>
<img src={cycle}/>
    </div> */}
    <div className='HIGHLISGHTS'>
    <div className='HIGHLISGHTS-left'>

    {/* <p>HIGHLISGHTS</p> */}
    <h3 data-aos="fade-right">Investment <span>Highlights</span></h3>
    <br/>
    <h4 data-aos="fade-right">
We cultivate relationships with specialized venture capital leaders, enabling access to top companies.*
</h4>

</div>
 <div className='HIGHLISGHTS-right'>
 <img src={img} data-aos="zoom-in"/>

</div>

    </div>
    <div className='Pioneering'>
        <h3 data-aos="zoom-in">Pioneering Since <span>the Beginning</span></h3>
        <br/>
        <p data-aos="zoom-in">Over the last twenty years, the firm has evolved from being a pioneer in secondary investments to becoming the leading provider of flexible capital solutions for the venture ecosystem. We propelled the seed-stage venture market and support our managers’ top companies with additional capital to grow.</p>
    </div>
    <Footer/>
  </>
  )
}

export default AngelInvesting