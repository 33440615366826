import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom';

function Blog() {
    const [blogs, setBlogs] = useState([]);



    useEffect(() => {
        const fetchBlogs = async () => {
          try {
            const response = await axios.get('https://onexinfinity-backend.vercel.app/api/blogs/');
            if (response.data && response.data.data) {
              setBlogs(response.data.data); // Set the fetched blogs data
            } else {
              console.error('Unexpected API response structure:', response);
            }
          } catch (error) {
            console.error('Error fetching blogs:', error);
          }
        };
    
        fetchBlogs();
      }, []);
  return (
   <>
    <Navbar/>
    <div className='banner banner3'>
    <h2>Blogs</h2>
    <br/>
    <p>Home / Blogs</p>
   
    </div>
    <div className='blogs'>
  
    <h3>Our <span>Blogs</span></h3>
    <br/>
    <br/>
    <div className="blogs-cards">
      {blogs.length > 0 ? (
        blogs.map((blog) => {
          // Formatting the published date
          const publishedDate = new Date(blog.publishedAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });

          return (
            <div key={blog._id} className="blogs-card">
              <img src={blog.image} alt={blog.title} />
            

         <div className='blogs-card-text'>
         <h3>{blog.title}</h3>
              <br />
              <div className="dates">
                <p>{publishedDate}</p>
                <Link to={`/blogdetail/${blog._id}`}>
        <h4>Read More</h4> {/* Link to detailed view */}
      </Link>
              </div>
         </div>
            </div>
          );
        })
      ) : (
        <p>Loading blogs...</p> // Simple loading state while fetching data
      )}
    </div>
    

    </div>
    <Footer/>
   </>
  )
}

export default Blog