import React from 'react'
import Navbar from '../Components/Navbar'
import img from '../Assets/image 22.png'
import icon from '../Assets/image 24.png'
import icon2 from '../Assets/image 25.png'
import icon3 from '../Assets/image 26.png'
import icon4 from '../Assets/image 27.png'

import img3 from '../Assets/Group 1000001778.png'
import img2 from '../Assets/Group 1000001777.png'
import Footer from '../Components/Footer'

function About() {
  return (
    <>
        <Navbar/>
        <div className='banner banner2'>
    <h2 data-aos="fade-right">About Us</h2>
 
    <p data-aos="fade-right">Home / About Us</p>
   
    </div>
    <div className='about-page'>
        <p data-aos="fade-right">About Us</p>
        <div className='about-page-div'>
            <h2 data-aos="fade-right">The Impossible mission Behind The OneXinfinity</h2>
            <h3 data-aos="fade-right">we understand the challenges and complexities that businesses face in today's dynamic marketplace. That's why we offer a comprehensive suite of consulting services.</h3>
        </div>
    </div>
    <div className='builtcareer'>
    <div className='builtcareer-left'>
<img src={img}/>
</div>
<div className='builtcareer-right'>
<p data-aos="fade-right">BUILD CAREER</p>
<h2 data-aos="fade-right">Learn with OneXinfinity grow your better future.</h2>
<h3 data-aos="fade-right">we understand the challenges and complexities that businesses face in today's dynamic marketplace. That's why we offer a comprehensive suite of consulting services.
we understand the challenges and complexities that businesses face in today's dynamic marketplace. That's why we offer a comprehensive suite of consulting services.
we understand the challenges and complexities that businesses face in today's dynamic marketplace. That's why we offer a comprehensive suite of consulting services.
we understand the challenges and complexities that businesses face in today's dynamic marketplace. That's why we offer a comprehensive suite of consulting services.
we understand the challenges and complexities that businesses face in today's dynamic marketplace. That's why we offer a comprehensive suite of consulting services.we understand the challenges and complexities that businesses face in today's dynamic marketplace. That's why we offer a comprehensive suite of consulting services.we understand the challenges and complexities that businesses face in today's dynamic marketplace. That's why we offer a comprehensive suite of consulting services.</h3>

<br/>
<button data-aos="fade-right">Explore Courses</button>

</div>
    </div>
    <div className='OURVALUES'>
   
    <h2 data-aos="fade-right">Strong values is bring great <span>team together & work.</span></h2>
    <h3 data-aos="fade-right">Discover the possibilities of online education and unlock your true of the potential! expand <br/>your mind with online beautiful</h3>
<div className='OURVALUES-cards'>
<div>
<div className='OURVALUES-card'>
<img src={icon}/>
<br/>
<br/>

<h3>Team Work</h3>

<p>Discover the possibilities of online education and unlock your true of the potential! Expand youmind with online</p>
</div>
</div>
<div>
<div className='OURVALUES-card'>
<img src={icon2}/>
<br/>
<br/>

<h3>Creativity</h3>

<p>Discover the possibilities of online education and unlock your true of the potential! Expand youmind with online</p>
</div>
</div>
<div>
<div className='OURVALUES-card'>
<img src={icon3}/>
<br/>
<br/>

<h3>Commitment</h3>

<p>Discover the possibilities of online education and unlock your true of the potential! Expand youmind with online</p>
</div>
</div>
<div>
<div className='OURVALUES-card'>
<img src={icon4}/>
<br/>
<br/>

<h3>Openness</h3>

<p>Discover the possibilities of online education and unlock your true of the potential! Expand youmind with online</p>
</div>
</div>
</div>
    </div>
    
    <div className='letstart' data-aos="zoom-in">
    <div className='letstart-div'>
    <div className='let-img'>
<img src={img2}/>
    </div>
    <div className='let-img2'>
    
    <h3>Find your next <span>courses with speed</span> and accuracy.</h3>
    <h4>I share what it takes to be a designer and how to take your skills to the next level. everything from basic design theory to the mindset.</h4>
   <br/>
    <div className='btns'>
<button>Explore Courses</button>
<button>Contact Us</button>

    </div>

</div>
  <div className='let-img'>
  <img src={img3}/>

</div>

</div>
    </div>
    <Footer/>
    </>
  )
}

export default About