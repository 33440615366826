import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

function Allcourses() {
  const [courses, setCourses] = useState([]); // State to hold the list of courses
  const [modal, setModal] = useState(false); // State to control modal visibility
  const [selectedCourse, setSelectedCourse] = useState(null); // State to hold selected course details

  // Fetch courses on component mount
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get('https://onexinfinity-backend.vercel.app/api/course');
        console.log('Courses fetched:', response.data.data); // Debugging log for courses data
        setCourses(response.data.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
        alert('Failed to fetch courses. Please try again later.');
      }
    };

    fetchCourses();
  }, []);

  // Function to handle enroll button click
  const handleEnrollNow = (courseId) => {
    console.log('Enroll button clicked for course ID:', courseId); // Debugging log
    setModal(true); // Open modal
    fetchCourseDetails(courseId); // Fetch course details after opening modal
  };

  // Fetch course details based on selected course ID
  const fetchCourseDetails = async (courseId) => {
    try {
      const response = await axios.get(`https://onexinfinity-backend.vercel.app/api/course/${courseId}`);
      console.log('Course details fetched:', response.data.data); // Debugging log for course details
      setSelectedCourse(response.data.data); // Set course details
    } catch (error) {
      console.error('Error fetching course details:', error);
      alert('Failed to fetch course details. Please try again later.');
      setSelectedCourse(null); // Reset selected course on error
    }
  };

  return (
    <>
      <Navbar />
      <div className='banner banner4'>
    <h2 data-aos="fade-right">Our Courses</h2>
    
    <p data-aos="fade-right">Home / Our Courses</p>
   
    </div>
      <br />
      <br />
      <div className='COURSES'>

<div className='COURSES-heading'>

<h2 data-aos="fade-right">Our <span>Courses</span></h2>
</div>
<br/>
<br/>
<div className="COURSES-cards">
         {courses.map((course) => (
           <div key={course._id} className="COURSES-card">
             <img src={course.image} alt={course.title} />
             <div className="COURSES-card-text">
               <p>{course.title}</p>
               <h3>{course.description}</h3>
               <h4>Duration: {course.duration} weeks</h4>
              
             </div>
             <div className='center'>
               <button className='enrollnow' onClick={() => handleEnrollNow(course._id)}>Enroll Now</button>
             </div>
           </div>
         ))}
       </div>
     </div>
      <br />
      <br />
      <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          {selectedCourse ? selectedCourse.title : 'Loading...'}
        </ModalHeader>
        <ModalBody>
          {selectedCourse ? (
            <div className='modal-div'>
            <div className='modal-div-left'>
            <img
                src={selectedCourse.image}
                alt={selectedCourse.title}
                style={{ width: '100%', marginBottom: '20px' }}
              />
            </div>
            <div className='modal-div-right'>
            <h2>{selectedCourse.title}</h2>

            <h3>{selectedCourse.description}</h3>
            <p><strong>Duration:</strong> {selectedCourse.duration} Month</p>
            <h4><strong>Instructor:</strong> {selectedCourse.instructor} Month</h4>

<br/>
            <div className='center'>
               <button className='enrollnow enrollnow2'>Enroll Now</button>
             </div>
            

</div>
             
             
            </div>
          ) : (
            <p>Loading course details...</p>
          )}
        </ModalBody>
      </Modal>
      <Footer />
    </>
  );
}

export default Allcourses;
