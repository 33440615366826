import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
        <div className='footer'>
        <ul>
        OneX<span>Infinity</span>
       
            <li>the quick fox jumps over the 
            lazy dog</li>
            
        </ul>
        <ul>
        Quick Links
        <Link className='none' to='/'>

        <li>Home</li>
        </Link>
        <Link className='none' to='/about'>

        
        <li>About Us</li>
        </Link>
        <Link className='none' to='/ngo'>


        <li>NGO</li>
        </Link>
        <Link className='none' to='/angel'>


        <li>Angel Investing</li>
        </Link>
        <Link className='none' to='/workshop'>

<li>Workshop</li>
</Link>

        </ul>
        <ul>
        Quick Links
        <Link className='none' to='/contact'>


        <li>Contact Us</li>
        </Link>
        <Link className='none' to='/ourcompanies'>


        <li>Our Companies</li>
        </Link>
        <Link className='none' to='/blog'>

<li>Blogs</li>
</Link>
<Link className='none' to='/allcourses'>

<li>Courses</li>
</Link>
        

        </ul>
        <ul>
        Follow Us
        <li className='socialicons'><i class="bi bi-instagram"></i><i class="bi bi-facebook"></i>
        <i class="bi bi-whatsapp"></i><i class="bi bi-linkedin"></i>
        <i class="bi bi-twitter"></i></li>
       

        </ul>

        </div>
        <div className='footer-bottom'>
<p>Made With Love By OneXinfinity All Right Reserved. Designed & Developed by <Link to='https://www.strixdigital.in/' style={{color:'white',textDecoration:'none'}}>Strix Digital</Link> </p>
        </div>
    </>
  )
}

export default Footer